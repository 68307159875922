import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import routes, {
  changepassword,
  home,
  dashboard,
  programs,
  discovery,
  partner,
  profile,
  programlanding,
  register,
  checkout,
  coursecompleted,
  coursetitle,
  forgotpassword,
  terms,
  facilitators
} from './userRoutes';
import RouteGuard from 'RouteGuard';
import NotFound from 'NotFound';
import { useDispatch } from 'react-redux';
import { clearStudentInteraction, clearStudentInteractionModal } from 'store/actions/inAppNotify';

function AppUser() {
  const location = useLocation();
  const dispatch = useDispatch();
  const unGuardedRoutes = [
    home,
    dashboard,
    programs,
    register,
    discovery,
    partner,
    facilitators,
    profile,
    programlanding,
    checkout,
    coursecompleted,
    coursetitle,
    forgotpassword,
    terms
    // delivery
  ];

  React.useEffect(() => {
    dispatch(clearStudentInteraction());
    dispatch(clearStudentInteractionModal());
  }, []);

  React.useEffect(() => {
    if (location.pathname.includes(home) || location.pathname.length === 1) {
      return;
    } else if (!routes.includes(location.pathname)) {
      return;
    }
    sessionStorage.setItem('_loc', location.pathname);
  }, [location]);

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route key={i} exact path={route.path}>
          {unGuardedRoutes.includes(route.path) ? (
            <route.component />
          ) : (
            <RouteGuard path={route.path}>{<route.component />}</RouteGuard>
          )}
        </Route>
      ))}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default AppUser;

import Axios from 'axios';

const service = 'Course';

export const courseCategoryList = async (
  key,
  isActive = true,
  usePaging = false,
  page = 1,
  pageSize = 50
) => {
  const { data } = await Axios.get(
    `/${service}/api/CourseCategory/List?IsActive=${isActive}&UsePaging=${usePaging}&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const language = async ({ isActive = true, usePaging = false, page = 1, pageSize = 50 }) => {
  const { data } = await Axios.get(`/${service}/api/Language/List`, {
    isActive,
    usePaging,
    page,
    pageSize
  });
  return data;
};

export const completedCourses = async (key, userId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Completed/${userId}`);
  return data;
};
export const getCourseUpdateHistory = async (key, courseId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Update/${courseId}`);
  return data;
};

export const courseList = async (
  key,
  institutionId,
  isActive = true,
  usePaging = false,
  page = 1,
  pageSize = 50
) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/List?InstitutionId=${institutionId}&IsActive=${isActive}&UsePaging=${usePaging}&Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const enrollmentStatistics = async (key, institutionId) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/FetchMonthlyEnrollmentStatistics?institutionId=${institutionId}`
  );
  return data;
};
export const courseStatistics = async (key, institutionId) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/GetInstitutionCourseStatistic?institutionId=${institutionId}`
  );
  return data;
};

export const courseEnrolled = async (key, userId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Enrolled/${userId}`);
  return data;
};
export const recent = async (key, userId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Recent/${userId}`);
  return data;
};
export const courseOverallPercentage = async key => {
  const { data } = await Axios.get(`/${service}/api/Course/Overall/Percentage`);
  return data;
};

export const courseEnrolledDetails = async (key, userId, courseId) => {
  if (!userId || !courseId) return null;
  const { data } = await Axios.get(`/${service}/api/Course/Enrolled/Details/${userId}/${courseId}`);
  return data;
};

export const getCourseSurveyList = async (key, isDraft) => {
  const { data } = await Axios.get(`/${service}/api/Survey/List?Draft=${isDraft}`);
  return data;
};

export const getCourseSurveyDetails = async (key, surveyId) => {
  if (!surveyId) return null;
  const { data } = await Axios.get(`/${service}/api/Survey/Details/${surveyId}`);
  return data;
};

export const getCoursesAssignedToSurvey = async (key, surveyId, page = 1, pageSize = 10) => {
  if (!surveyId) return null;
  const { data } = await Axios.get(
    `/${service}/api/Course/Survey/Courses/${surveyId}?Page=${page}&PageSize=${pageSize}`
  );
  return data;
};
export const getUnAssignedCourses = async key => {
  const { data } = await Axios.get(`/${service}/api/Course/NoSurvey/Courses`);
  return data;
};

export const unAssignSurvey = async (key, courseId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Survey/UnAssign/${courseId}`);
  return data;
};

export const toggleActivateSurvey = async surveyId => {
  const { data } = await Axios.get(`/${service}/api/Survey/Toggle/${surveyId}`);
  return data;
};

export const adminPreviewCourse = async (key, courseId) => {
  if (!courseId) return null;
  const { data } = await Axios.get(`/${service}/api/Course/AdminPreviewCourse/${courseId}`);
  return data;
};

export const userCourseSurvey = async (key, courseId) => {
  if (!courseId) return null;
  const { data } = await Axios.get(`/${service}/api/Survey/Course/${courseId}`);
  return data;
};

export const checkEnrollment = async (key, userId, courseId) => {
  const { data } = await Axios.get(`/${service}/api/Course/CheckEnrollment/${userId}/${courseId}`);
  return data;
};

export const getCourseBuilder = async courseId => {
  try {
    const { data } = await Axios.get(`/${service}/api/Course/Builder/${courseId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSummaryByCatgory = async () => {
  try {
    const { data } = await Axios.get(`/${service}/api/Course/SummaryByCatgory`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const courseSearch = async (
  key,
  institutionId,
  isActive = true,
  usePaging = false,
  page = 1,
  pageSize = 50
) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/Search?Page=${page}&PageSize=${pageSize}`
  );
  return data;
};

export const featuredCourses = async () => {
  try {
    const { data } = await Axios.get(`/${service}/api/Course/Home/Display`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const courseCategoryToggle = async courseCategoryId => {
  const { data } = await Axios.get(
    `/${service}/api/CourseCategory/ActivateDeactivate/${courseCategoryId}`
  );
  return data;
};

export const checkIfCourseUpdateIsAWaitingApproval = async (key, courseId) => {
  const { data } = await Axios.get(`/${service}/api/Course/Update/Check/${courseId}`);
  return data;
};
export const getInstructors = async (key, institutionId, isActive = true) => {
  const { data } = await Axios.get(
    `/${service}/api/Instructor/List?InstitutionId=${institutionId}&IsActive=${isActive}`
  );
  return data;
};

export const searchRevenue = async (key, search = '', id = 0, page = 1, pageSize = 50) => {
  let queryParams = '';
  if (search) queryParams = `?search=${search}&id=${id}&page=${page}&pageSize=${pageSize}`;
  else queryParams = `?id=${id}&page=${page}&pageSize=${pageSize}`;
  const { data } = await Axios.get(`/${service}/api/Revenue/Search${queryParams}`);
  return data;
};

export const getRevenuePartners = async (key, revenueId, search = '', id) => {
  let queryParams = '';
  if (search) queryParams = `?revenueId=${revenueId}&search=${search}&id=${id}`;
  else queryParams = `?RevenueId=${revenueId}`;
  const { data } = await Axios.get(`/${service}/api/Revenue/GetRevenuePartners${queryParams}`);
  return data;
};

export const getCourseAwaitingQAList = async (
  courseTitle,
  startDate = '',
  endDate = '',
  approvalstatus = 4,
  courseCategoryId = 0,
  page = 1,
  pageSize = 1000,
  institutionId = 0
) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/AwaitingCourseApproval?${
      courseTitle && `courseTitle=${courseTitle}&`
    }page=${page}&pageSize=${pageSize}&approvalstatus=${approvalstatus}&courseCategoryId=${courseCategoryId}&institutionId=${institutionId}${
      startDate ? `&StartDate=${startDate}` : ''
    }${endDate ? `&EndDate=${endDate}` : ''}`
  );
  return data;
};

export const getInstitutionApprovedCourses = async (
  institutionId,
  courseTitle,
  startDate = '',
  endDate = '',
  courseCategoryId = 0,
  page = 1,
  pageSize = 1000
) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/InstitutionApprovedCourses?${
      courseTitle && `courseTitle=${courseTitle}&`
    }institutionId=${institutionId}&page=${page}&pageSize=${pageSize}&courseCategoryId=${courseCategoryId}${
      startDate ? `&StartDate=${startDate}` : ''
    }${endDate ? `&EndDate=${endDate}` : ''}`
  );
  return data;
};

export const getInstitutionRejectedCourses = async (
  institutionId,
  courseTitle,
  startDate = '',
  endDate = '',
  courseCategoryId = 0,
  page = 1,
  pageSize = 1000
) => {
  const { data } = await Axios.get(
    `/${service}/api/Course/InstitutionRejectedCourses?${
      courseTitle && `courseTitle=${courseTitle}&`
    }institutionId=${institutionId}&page=${page}&pageSize=${pageSize}&courseCategoryId=${courseCategoryId}${
      startDate ? `&StartDate=${startDate}` : ''
    }${endDate ? `&EndDate=${endDate}` : ''}`
  );
  return data;
};

import React from 'react';
import SkillbetaLogo from 'assets/images/skillbeta_logo.gif';
import {
  changepassword,
  discovery,
  home,
  register as RegisterPage,
  forgotpassword
} from 'userRoutes';
import { animate } from 'utils';
import {
  Box,
  Form,
  Button,
  TextInput,
  FormField,
  Heading,
  Paragraph,
  Text,
  CheckBox,
  Spinner,
  ResponsiveContext
} from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { signInClear } from 'store/actions/auth';
import AppData from 'AppData';
import styled from 'styled-components';
import { isMobile, isTablet } from 'utils';

import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { adminlogin, studentlogin, googleSignIn } from 'api/mutations/Authentication';
import { signIn, allPermissionsAction } from 'store/actions/auth';
import ButtonLoader from 'components/common/ButtonLoader';
import buildJson from 'configs/json/build.json';
import { getAllPermissions } from 'api/queries/Authentication';
import Facebook from 'assets/svgs/facebookIcon.svg';
import Google from 'assets/svgs/google-symbol.svg';
import Linkedin from 'assets/svgs/linkedin.svg';
import { showToast } from 'store/actions/toast';
import device from 'configs/responsive/mediaQueries';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { emailRegex } from 'data/regex';

const { mobile } = device;

const SignInForm = ({ showSignin, mobile }) => {
  const [checked, setChecked] = React.useState(true);
  const history = useHistory();
  const [canRedirect, setCanRedirect] = React.useState(false);
  const [passwordReveal, setPasswordReveal] = React.useState(false);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { allPermissions } = useSelector(state => state.auth);
  const { data: allPermissionsData, refetch } = useQuery('getAllPermissions', getAllPermissions);
  const { register, errors, handleSubmit } = useForm();
  const size = React.useContext(ResponsiveContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [loginError, setLoginError] = React.useState(null);
  const [formMessageAnimate] = React.useState([
    animate({ type: 'fadeIn', delay: 500, duration: 500 }),
    animate({ type: 'slideUp', delay: 500, duration: 500 })
  ]);

  const externalloginUrl = window['configs'].externalloginUrl;

  const onSubmit = async ({ userName, password }) => {
    if (isSubmitting) return;

    try {
      setLoginError(null);
      setIsSubmitting(true);
      if (!allPermissions) refetch();
      const data =
        buildJson.build === AppData.userBuildType
          ? await studentlogin({ userName, password })
          : await adminlogin({ userName, password });
      if (data.success) {
        //setShowSignin(false);
        dispatch(showToast('success', 'Login successfully'));

        dispatch(signIn(data.data));
      } else {
        setLoginError(data.description);
        // dispatch(showToast('warning', data.description));
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.message === 'Network Error') {
        setLoginError(
          <>
            We couldn't connect to the server.
            <br />
            Check your network or contact your admin
          </>
        );
      } else {
        setLoginError(error.message);
      }
    }
  };

  React.useEffect(() => {
    if (allPermissionsData)
      dispatch(allPermissionsAction({ allPermissions: allPermissionsData?.data }));
  }, [dispatch, allPermissionsData]);

  // // clear last location
  // React.useEffect(() => {
  //   const loc = sessionStorage.getItem('_loc');
  //   if (loc && loc.length === 1) {
  //     sessionStorage.removeItem('_loc');
  //   }
  // }, []);

  // clear sign in error side effect
  React.useEffect(() => {
    dispatch(signInClear());
  }, [dispatch]);

  // redirect on success
  React.useEffect(() => {
    if (auth.success) {
      setCanRedirect(auth.success);
    }
  }, [auth.success]);

  if (canRedirect) {
    // redirect to change password on first login
    if (auth.isFirstLogin) {
      const location = sessionStorage.getItem('_loc');
      if (location) return <Redirect to={location} />;

      if (auth.role === AppData.ROLE.STUDENT) {
        return (
          <Redirect
            to={
              history.location.state && history.location.state !== '/register'
                ? history.location.state
                : discovery
            }
          />
        );
      } else {
        return <Redirect to={changepassword} />;
      }
    } else if (auth.userId) {
      if (sessionStorage.getItem('_loc')) {
        const location = sessionStorage.getItem('_loc');

        if (location.includes(home)) return <Redirect to={''} />;

        // redirect to last location
        if (localStorage.getItem('_u') === auth.userId.toString())
          return <Redirect to={location} />;
      }
      // redirect to discovery on login successful
      return (
        <Redirect
          to={
            history.location.state && history.location.state !== '/register'
              ? history.location.state
              : discovery
          }
        />
      );
    }
  }

  const toggleSignin = () => {
    //check to see if you have been redirected to login from another page
    if (history.location.state && !auth.userId) return 'block';

    //determines wether to show signup form or not
    if (mobile) {
      if (showSignin) return 'block';
      return 'none';
    } else {
      if (auth?.userId) return 'none';
    }
  };

  const handlePasswordReveal = e => {
    e.stopPropagation();
    setPasswordReveal(!passwordReveal);
  };

  return (
    <Box
      background={{ color: '#fff' }}
      style={{
        display: toggleSignin(),
        width: `${isMobile(size) ? '20rem' : '22.5rem'}`,
        minHeight: `${isMobile(size) ? '22em' : '25em'}`,
        zIndex: 99,
        borderRadius: '5px',
        position: 'absolute',
        top: `${isMobile(size) ? '5.9em' : '8em'}`,
        right: `${isMobile(size) ? '1.2em' : '1.5em'}`
      }}
      pad="medium">
      <Heading level={isMobile(size) ? '4' : '3'} margin={{ top: 'none' }}>
        Sign In
      </Heading>
      <Paragraph margin={{ top: 'none' }} style={{ fontSize: `${isMobile(size) && '.85em'}` }}>
        New user?{' '}
        <Text
          onClick={() => history.push(RegisterPage)}
          color="#0966b8"
          margin={{ left: '1rem' }}
          style={{
            fontSize: `${isMobile(size) ? '.85em' : '1em'}`,
            cursor: 'pointer'
          }}>
          create an account
        </Text>
      </Paragraph>
      {loginError && <Paragraph style={{ color: 'red', fontSize: '14px' }}>{loginError}</Paragraph>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box margin={{ bottom: 'small' }}>
          <FormField margin={{ bottom: 'none' }} name="name" htmlFor="textinput-id">
            <TextInput
              size="small"
              placeholder="email"
              ref={register({
                required: 'email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Not a valid email address'
                }
              })}
              id="username-id"
              type="email"
              name="userName"
            />
          </FormField>
          {errors.userName && (
            <Text
              color="status-critical"
              size="small"
              alignSelf={isMobile(size) ? 'start' : 'end'}
              margin={{ left: isMobile(size) ? 'none' : 'xsmall' }}>
              {errors.userName.message}
            </Text>
          )}
        </Box>
        <FormField name="name" htmlFor="textinput-id" margin={{ bottom: 'medium' }}>
          <TextInput
            size="small"
            placeholder="Password"
            ref={register({ required: 'Password is required' })}
            id="password-id"
            name="password"
            type={passwordReveal ? 'text' : 'password'}
            icon={
              <Box
                style={{ cursor: 'pointer', pointerEvents: 'auto' }}
                onClick={e => handlePasswordReveal(e)}>
                {passwordReveal ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </Box>
            }
            reverse
          />
        </FormField>
        <ForgotText onClick={() => history.push(forgotpassword)}>Forgot password?</ForgotText>
        {/* <FormField name="name" htmlFor="textinput-id" style={{border: 'none'}}> */}
        {/* <CheckBox
            checked={checked}
            label="Keep me signed in"
            onChange={event => setChecked(event.target.checked)}
          /> */}
        {/* </FormField> */}
        <Button
          type="submit"
          label={!isSubmitting && 'Start learning'}
          margin={{ top: '1rem' }}
          style={{
            display: 'block',
            width: '100%',
            height: '3rem',
            color: '#fff',
            border: '1px solid #0966b8',
            background: '#0966b8'
          }}
          icon={isSubmitting ? <ButtonLoader isSubmitting={isSubmitting} /> : null}
        />
        <Box
          direction="row"
          gap="small"
          margin={{ top: '2.3rem' }}
          style={{ justifyContent: 'center' }}>
          <Box background={{ color: '#bfbfbf' }} width="4.5rem" height="1px"></Box>
          <Box margin={{ top: '-.6rem' }}>
            <Text size=".8em">Or sign in with</Text>
          </Box>
          <Box background={{ color: '#bfbfbf' }} width="4.5rem" height="1px"></Box>
        </Box>
        <Box direction="row" style={{ margin: ' 1.3em auto 0 auto!important' }}>
          <div style={{ margin: 'auto', marginTop: '1.5rem' }}>
            <a href={`${externalloginUrl}/GoogleLogin`}>
              <img
                src={Google}
                width={isMobile(size) ? '20px' : '30px'}
                height={isMobile(size) ? '30px' : '40px'}
                style={{ marginRight: '1.6rem' }}
                alt="Google SignIn"
              />
            </a>

            <a href={`${externalloginUrl}/LinkedInLogin`}>
              <img
                src={Linkedin}
                width={isMobile(size) ? '20px' : '30px'}
                height={isMobile(size) ? '30px' : '40px'}
                alt="FaceBook SignIn"
              />
            </a>
          </div>
        </Box>
      </Form>
    </Box>
  );
};

SignInForm.propTypes = {};

export default SignInForm;

const Ul = styled.ul`
  padding-inline-start: 0;
  margin: 0.7em 0 0 0 !important;
`;
const Li = styled.li`
  list-style: none;
  display: inline;
  margin-left: 3rem;
  color: #fff;
`;

const NavLink = styled(Link)`
  color: ${props => `${props.color}!important`};
`;

const ForgotText = styled(Text)`
  font-size: 0.9em;
  margin-top: -1.4rem;
  margin-bottom: 1.5rem;
  display: block !important;
  color: #0966b8;
  cursor: pointer;
  transition: all 0.1s ease-out;
  float: right;
  &:hover {
    transform: scale(1.05);
    font-weight: bold;
  }
  @media ${mobile} {
    display: block !important;
    margin-top: -0.7rem;
  }
`;

import axios from 'axios';

const service = 'Course';

export const courseUploadViewing = async ({
  UserId,
  courseId,
  currentModule,
  currentSection,
  secondsWatched,
  isCompleted,
  lastDateVisted
}) => {
  const { data } = await axios.post(`/${service}/api/Course/Uploadviewing/${UserId}`, {
    courseId,
    currentModule,
    currentSection,
    secondsWatched,
    isCompleted,
    lastDateVisted
  });
  return data;
};

export const courseCreateUpdate = async ({
  courseId,
  institutionId,
  title,
  description,
  courseCategoryId,
  courseType,
  duration,
  startDate,
  endDate,
  stepNo,
  isPaid,
  hasSurvey
}) => {
  const { data } = await axios.post(`/${service}/api/Course/CreateUpdate`, {
    courseId,
    institutionId,
    title,
    description,
    courseCategoryId,
    courseType,
    duration,
    startDate,
    endDate,
    stepNo,
    isPaid,
    hasSurvey
  });
  return data;
};

export const courseOtherDetails = async ({
  courseId,
  institutionId,
  headLine,
  overView,
  courseLevel,
  languageId,
  objectives,
  effort,
  instructorName,
  courseThumbNail,
  courseBanner,
  coursePromotionalVideo,
  promote,
  stepNo
}) => {
  const formData = new FormData();
  formData.append('CourseId', courseId);
  formData.append('InstitutionId', institutionId);
  formData.append('HeadLine', headLine);
  formData.append('OverView', overView);
  formData.append('CourseLevel', courseLevel);
  formData.append('LanguageId', languageId);
  formData.append('Objectives', objectives);
  formData.append('Effort', effort);
  formData.append('InstructorName', instructorName);
  formData.append('CourseThumbNail', courseThumbNail);
  formData.append('CourseBanner', courseBanner);
  formData.append('CoursePromotionalVideo', coursePromotionalVideo);
  formData.append('Promote', promote);
  formData.append('StepNo', stepNo);

  const { data } = await axios.post(`/${service}/api/Course/Update/OtherDetails`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const courseUpload = async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('File', file);

    const { data } = await axios.post(`/${service}/api/Course/Upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const courseBuilder = async ({ payload, saveDraft, publish }) => {
  const { data } = await axios.post(
    `/${service}/api/Course/Builder?SaveDraft=${saveDraft}&Publish=${publish}`,
    payload
  );
  return data;
};
export const createSurvey = async ([surveyData, isDraft = false]) => {
  const { data } = await axios.post(`/${service}/api/Survey/Create?Draft=${isDraft}`, surveyData);
  return data;
};
export const updateSurvey = async ([surveyData, isDraft = false]) => {
  const { data } = await axios.post(`/${service}/api/Survey/Update?Draft=${isDraft}`, surveyData);
  return data;
};

export const assignSurvey = async assignData => {
  const { data } = await axios.post(`/${service}/api/Course/Survey/Assign`, assignData);
  return data;
};
export const selectSurvey = async surveyData => {
  const { data } = await axios.post(`/${service}/api/Course/Survey/Update`, surveyData);
  return data;
};

export const coursePricing = async ({ courseId, currencyId, basePrice, vat, gatewayCharge }) => {
  const { data } = await axios.post(`/${service}/api/Course/Pricing`, {
    courseId,
    currencyId,
    basePrice,
    vat,
    gatewayCharge
  });
  return data;
};

export const courseSearch = async ({
  page,
  pageSize,
  parameter,
  courseCategoryIds,
  institutionIds,
  courseLevels,
  languageIds
}) => {
  const payload = {
    courseCategoryIds: courseCategoryIds,
    parameter: parameter,
    institutionIds: institutionIds,
    courseLevels: courseLevels,
    languageIds: languageIds
  };

  const { data } = await axios.post(
    `/${service}/api/Course/Search?Page=${page}&PageSize=${pageSize}`,
    payload
  );
  return data;
};

export const courseLanguageSearch = async ({ isActive, usePaging, page = 1, pageSize = 50 }) => {
  const { data } = await axios.get(
    `/${service}/api/Language/List?${isActive ? `&IsActive=${isActive}` : ''}${
      usePaging ? `&UsePaging=${usePaging}` : ''
    }&page=${page}&pageSize=${pageSize}`
  );
  return data;
};

export const createCourseCategory = async (name, code) => {
  try {
    const { data } = await axios.post(`/${service}/api/CourseCategory/Create`, {
      name,
      code
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCourseCategory = async ({ id, name, code }) => {
  try {
    const queryString = `?id=${id}${name ? `&name=${name}` : ''}${code ? `&code=${code}` : ''}`;
    const { data } = await axios.post(`/${service}/api/CourseCategory/Update${queryString}`);
    return data;
  } catch (error) {
    throw error;
  }
};

// export const CourseEnrollment = async ({ payload }) => {
//   const { data } = await axios.post(
//     `/${service}/api/Course/Enroll`,
//     payload
//   );
//   return data;
// };

export const courseEnrollment = async ({ userId, courseId }) => {
  const { data } = await axios.post(`/${service}/api/Course/Enroll`, {
    userId,
    courseId
  });
  return data;
};

export const coursePayment = async ({
  action, // Process or Log
  referenceNo,
  courseId,
  userId,
  email,
  amount,
  paymentChannel,
  discountCode
}) => {
  try {
    const { data } = await axios.post(`/${service}/api/Payment/${action}`, {
      referenceNo,
      courseId,
      userId,
      email,
      amount,
      paymentChannel,
      discountCode
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createRevenue = async ({ name, revenuePartnerModel }) => {
  const { data } = await axios.post(`/${service}/api/Revenue/Create`, {
    name,
    revenuePartnerModel
  });
  return data;
};

export const getRevenues = async (key, search = '', id = 0, page = 1, pageSize = 50) => {
  const { data } = await axios.get(
    `/${service}/api/Revenue/Search?search=${search}&id=${id}&page=${page}&pageSize=${pageSize}`
  );
  return data;
};

export const getCourses = async (
  key,
  institutionId,
  isActive = true,
  usePaging = false,
  page = 1,
  pageSize = 50
) => {
  const { data } = await axios.get(
    `/${service}/api/Course/List?institutionId=${institutionId}&isActive=${isActive}&usePaging=${usePaging}&page=${page}&pageSize=${pageSize}`
  );
  return data;
};

export const updateScore = async (userId, courseQuizId, courseId, module, section, score) => {
  const { data } = await axios.post(`/${service}/api/Course/Quiz/UpdateScore/${userId}`, {
    courseQuizId,
    courseId,
    module,
    section,
    score
  });
  return data;
};

export const computePricing = async ({ basePrice, courseId, institutionId, discountCode }) => {
  const { data } = await axios.post(`/${service}/api/Course/Compute/Pricing`, {
    basePrice,
    courseId,
    institutionId,
    discountCode
  });
  return data;
};

import Axios from 'axios';

const service = 'Authentication';

export const getStudentByUserId = async userId => {
  const { data } = await Axios.get(
    `/${service}/api/Account/Student/GetStudentByUserId?userId=${userId}`
  );
  return data;
};

export const getAllPermissions = async () => {
  const { data } = await Axios.get(`/${service}/api/RolePermissionManager/GetAllPermissions`);
  return data;
};

export const getAllRoles = async (key, roleType) => {
  const { data } = await Axios.get(
    `/${service}/api/RolePermissionManager/GetAllRoles${
      roleType !== '' ? `?RoleType=${roleType}` : ''
    }`
  );
  return data;
};

export const getAdminByUserId = async (key, userId) => {
  const { data } = await Axios.get(
    `/${service}/api/Account/User/GetAdminByUserId?userId=${userId}`
  );
  return data;
};
export const getAdminByUserId2 = async (key, userId) => {
  const { data } = await Axios.get(
    `/${service}/api/Account/Admin/GetAdminByUserId?userId=${userId}`
  );
  return data;
};
export const StudentGetAllUser = async () => {
  const { data } = await Axios.get(`/${service}/api/Account/Student/GetUsers`);
  return data;
};

export const searchRoleDetails = async (key, search = '', pageNumber = 1, pageSize = 1000) => {
  let queryString = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (search) queryString = queryString.concat(`&search=${search}`);
  const { data } = await Axios.get(
    `/${service}/api/RolePermissionManager/SearchRoleDetails${queryString}`
  );
  return data;
};

export const getUserInfoByInstitutionId = async (
  key,
  institutionId,
  search = '',
  page = 1,
  pageSize = 50,
  isActive
) => {
  const { data } = await Axios.get(
    `/${service}/api/Account/User/GetUserInfoByInstitutionId?${
      search !== '' ? `search=${search}&` : ''
    }Page=${page}&PageSize=${pageSize}&institutionid=${institutionId}${
      isActive ? `&isActive=${isActive}` : ''
    }`
  );
  return data;
};

export const getWorkflowUserInfo = async (
  key,
  institutionId,
  search = '',
  page = 1,
  pageSize = 50,
  isActive
) => {
  const { data } = await Axios.get(
    `/${service}/api/Account/Admin/GetWorkflowUserInfo?institutionid=${institutionId}${
      isActive ? `&isActive=${isActive}` : ''
    }`
  );
  return data;
};

export const getAdminUserByInstitutionId = async (key, id) => {
  const { data } = await Axios.get(
    `/${service}/api/Account/Admin/GetAdminUsers?institutionId=${id}`
  );
  return data;
};
